import { connectionInterestService } from "../service/connection.service";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { RegisterService } from "../service/register.service";
import { ApiService } from "../service/api.service";
import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, HostListener, OnInit,ViewChild, Output } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { animate, state, style, transition, trigger } from "@angular/animations";

@Component({
  selector: "app-previous-education",
  templateUrl: "./previous-education.component.html",
  styleUrls: ["./previous-education.component.scss"],
  animations: [
    trigger("height4sAnim", [
      state("true", style({ opacity: 1, height: "auto"})),
      state("void", style({ opacity: 0, height: 0})),
      transition("* => *", [animate("0.4s")]),
    ]),
    trigger("btnStep3sAnim", [
      state("true", style({ "margin-left": 0, opacity: 0 })),
      state("void", style({ opacity: 1, "margin-left": -280, "z-index": 99 })),
      transition("* => *", [animate("0.3s")]),
    ]),
    trigger("height2sAnim", [
      state("true", style({ opacity: 1, height: "auto" })),
      state("void", style({ opacity: 0, height: 0 })),
      transition("* => *", [animate("0.2s")]),
    ]),
    trigger("balloonEffect", [
      state(
        "true",
        style({ backgroundColor: "green", transform: "scale(1.5)" })
      ),
      state("void", style({ backgroundColor: "red", transform: "scale(1)" })),
      transition("* => *", [animate("2s")]),
    ]),
  ],
})
export class PreviousEducationComponent implements OnInit, AfterViewChecked {
  fildShowvalue: boolean[];
  formSubmited: boolean;
  previousEducation: UntypedFormGroup;
  selectedDegreeTitle: any;
  academicPogramLevelData = [];
  selectedProgram: any;
  dualEnrollmentCollegeDetails: UntypedFormArray;
  display: any = false;
  academicInterest: any;
  isSearchData: boolean = true;
  isCollegeSearch: boolean = true;
  searchInput: any;
  searchCollegeInput: any;
  searchInputValue: any;
  previousEduEditData: any = null;
  graduationMonth = [
    "01 - January",
    "02 - February",
    "03 - March",
    "04 - April",
    "05 - May",
    "06 - June",
    "07 - July",
    "08 - August",
    "09 - September",
    "10 - October",
    "11 - November",
    "12 - December",
  ];

  graduationYear = [
    "2022",
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
    "2015",
    "2014",
    "2013",
    "2012",
    "2011",
    "2010",
    "2009",
    "2008",
    "2007",
    "2006",
    "2005",
    "2004",
    "2003",
    "2002",
    "2001",
    "2000",
    "1999",
    "1998",
    "1997",
    "1996",
    "1995",
    "1994",
    "1993",
    "1992",
    "1991",
    "1990",
    "1989",
    "1987",
    "1986",
    "1985",
    "1984",
    "1983",
  ];
  schoolList: string[] = [];

  highSchoolList: any = [];

  selectedSchool: any = [];
  selectedCollege;

  isPreviousEducationViewMode: any = false;

  public progress: number;
  public formObserver: Subscription;

  @Output() progressBarData = new EventEmitter<any>();
  collegesSearchChar: number;
  collegeSearchChar: number;

  constructor(
    private previousEducationBuilder: UntypedFormBuilder,
    private _connectionInterestService: connectionInterestService,
    private registerService: RegisterService,
    private cdRef: ChangeDetectorRef,
    private apiService: ApiService,

  ) { }

  ngOnInit() {
    this._connectionInterestService.FildShowValue.subscribe(
      (fildShowvalue) => (this.fildShowvalue = fildShowvalue)
    );
    this._connectionInterestService.formSubmited.subscribe(
      (formSubmited) => (this.formSubmited = formSubmited)
    );

    this.previousEducation = this.previousEducationBuilder.group({
      highSchoolDiploma: new UntypedFormControl("", Validators.required),
      highSchoolCountryName: new UntypedFormControl(""),
      highSchoolFirstName: new UntypedFormControl(""),
      highSchoolLastName: new UntypedFormControl(""),
      dualEnrollmentPreviously: new UntypedFormControl(""),
      attendedCollegesUniversities: new UntypedFormControl(""),
      officialTranscripts: new UntypedFormControl(""),
      highSchoolDiplomaOrGEDPriorTEnrolling: new UntypedFormControl(""),
      expelledOrDismissedEducationalInstitution: new UntypedFormControl(""),
      homeSchoolFirstName: new UntypedFormControl(""),
      homeSchoolLastName: new UntypedFormControl(""),
      GEDLastName: new UntypedFormControl(""),
      GEDFirstName: new UntypedFormControl(""),
      GEDAwardState: new UntypedFormControl(""),
      FAACommercialPilotCertificate: new UntypedFormControl(""),
      FAAAirframePowerplantCertificate: new UntypedFormControl(""),
      additionalFlightTraining: new UntypedFormControl(""),
      whereWillCompleteFlightTraining: new UntypedFormControl(""),
      whichWillCompleteFlightTraining: new UntypedFormControl(""),
      dualEnrollmentHighSchoolState: new UntypedFormControl(""),
      dualEnrollmentCollegeDetails: this.previousEducationBuilder.array([
        this.initializeDualEnrollment(),
      ]),
      bachelorSchoolName: new UntypedFormControl(""),
      bachelorSchoolGraduationDate: new UntypedFormControl(""),
      doctorateSchoolName: new UntypedFormControl(""),
      doctorateGraduationDate: new UntypedFormControl(""),
      graduationMonth: new UntypedFormControl(""),
      graduationYear: new UntypedFormControl(""),
      priorStandardCertificate: new UntypedFormControl(""),
      finalCollegeMark: new UntypedFormControl(""),
      finalCollegeList: new UntypedFormControl(""),
      homeSchool: new UntypedFormControl(""),
      homeSchoolGraduationMonth: new UntypedFormControl(""),
      homeSchoolGraduationYear: new UntypedFormControl(""),
      highSchool: new UntypedFormControl(""),
      highSchoolGraduationMonth: new UntypedFormControl(""),
      highSchoolGraduationYear: new UntypedFormControl(""),
      college: new UntypedFormControl(""),
      collegeGraduationMonth: new UntypedFormControl(""),
      collegeGraduationYear: new UntypedFormControl(""),
      gedGraduationMonth: new UntypedFormControl(""),
      gedGraduationYear: new UntypedFormControl(""),
      collegeSearch: new UntypedFormControl(""),
      highSchoolSearch: new UntypedFormControl(""),
      oweBalanceSchool: new UntypedFormControl(""),
      expelledInstitution: new UntypedFormControl(""),
    });

    this.formObserver = this.previousEducation.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => this.onFormChanged(this.previousEducation));

    // service data
    this.registerService.getData().subscribe((data) => {
      this.academicInterest = data;
      if (!this.academicInterest[0]) return;
      this.selectedProgram = this.academicInterest[0].selectedItem;
      this.selectedDegreeTitle = this.academicInterest[0].formValue.degreeLevel;
    });
  }
  ngAfterViewChecked() {
    this.dualEnrollmentChangeValue.valueChanges.subscribe((checked) => {
      let groupItems: any = (
        this.previousEducation.get("dualEnrollmentCollegeDetails") as UntypedFormArray
      ).controls;
      if (checked == "yes") {
        for (let item of groupItems) {
          item.controls["dualEnrollmentCollegeNameAttended"].setValidators([
            Validators.required,
          ]);
          item.controls["dualEnrollmentLastYearAttended"].setValidators([
            Validators.required,
          ]);
          item.controls["dualEnrollmentHighSchoolCountry"].setValidators([
            Validators.required,
          ]);
          item.controls["degreeLevelReceivedFromSchoolAbove"].setValidators([
            Validators.required,
          ]);
          this.cdRef.detectChanges();
        }
      } else {
        for (let item of groupItems) {
          item.controls["dualEnrollmentCollegeNameAttended"].setValidators(
            null
          );
          item.controls["dualEnrollmentLastYearAttended"].setValidators(null);
          item.controls["dualEnrollmentHighSchoolCountry"].setValidators(null);
          item.controls["degreeLevelReceivedFromSchoolAbove"].setValidators(
            null
          );
        }
      }
    });
  }

  // get form controls
  get dualEnrollmentChangeValue() {
    return this.previousEducation.get(
      "dualEnrollmentPreviously"
    ) as UntypedFormControl;
  }

  onFormChanged(form: UntypedFormGroup): void {
    this.progress = this.calculateFormProgress(form);
    this.progressBarData.emit(this.progress);
  }

  calculateFormProgress(form: UntypedFormGroup): number {
    const controlCount = Object.keys(form.controls).length;
    let validCount = 0;
    for (const [key, value] of Object.entries(form.controls)) {
      if (value.value != "") validCount++;
    }
    return ((validCount * 0.34) / 45) * 100;
  }

  // Init DualEnrollment
  initializeDualEnrollment(): UntypedFormGroup {
    return this.previousEducationBuilder.group({
      dualEnrollmentCollegeNameAttended: new UntypedFormControl(""),
      dualEnrollmentLastYearAttended: new UntypedFormControl(""),
      dualEnrollmentHighSchoolCountry: new UntypedFormControl(""),
      degreeLevelReceivedFromSchoolAbove: new UntypedFormControl(""),
    });
  }

  // show Master based on the degree type slected
  showSchollDiploma() {
    if (
      this.selectedDegreeTitle === "Bachelor's Degree" ||
      this.selectedDegreeTitle === "Graduate Certificate" ||
      this.selectedDegreeTitle === "Undergraduate Certificate" ||
      this.selectedDegreeTitle === "Associate's Degree"
    ) {
      return true;
    } else {
      return false;
    }
  }


  educationFormData = { formValue: {}, selectedItem: {} };
  // Submit Academic Form
  previousEducationSubmit() {

    if (true) {
      this.previousEduEditData = this.previousEducation.value;

      this.updateFormStateOnSubmit();

      window.scrollTo({ top: 100, left: 0, behavior: "smooth" });

      // get form data
      this.updateFormData();
      this.educationFormData = {
        formValue: this.previousEducation.value,
        selectedItem: this.selectedProgram,
      };
      this.registerService.pushData(this.educationFormData);

    } else {
      this._connectionInterestService.fldShowValueChange([
        false,
        true,
        false,
        false,
      ]);
      this.isPreviousEducationViewMode = false;
    }
  }

  // update form data when edit form fields
  previousEducationFormData;
  updateFormData() {
    this.previousEducationFormData = this.previousEducation.value;
    this.registerService.updateData(this.educationFormData);
  }

  // show Master based on the degree type slected
  showMasterFields() {
    if (this.selectedDegreeTitle === "Master's Degree") {
      return true;
    } else {
      return false;
    }
  }

  // show Doctorate based on the degree type slected
  showDoctorateFields() {
    if (this.selectedDegreeTitle === "Doctorate") {
      return true;
    } else {
      return false;
    }
  }

  // remove enrollment field
  removeDualEnrollmentColleges(i: number) {
    this.dualEnrollmentCollegeDetails = this.previousEducation.get(
      "dualEnrollmentCollegeDetails"
    ) as UntypedFormArray;
    this.dualEnrollmentCollegeDetails.removeAt(i);
  }

  // get enrollment field value
  dualEnrollmentCollegeDetailsValued: any;
  dualEnrolActive: Boolean = true;
  getdualEnrollmentCollegeDetails(getIndex: number) {
    this.dualEnrollmentCollegeDetailsValued = this.previousEducation.get(
      "dualEnrollmentCollegeDetails"
    );
    if ((this.dualEnrollmentCollegeDetailsValued.length = 3)) {
      this.dualEnrollmentCollegeDetails.removeAt(0);
      this.dualEnrolActive = !this.dualEnrolActive;
    } else {
      this.dualEnrolActive = true;
    }
  }

  // get enrollment field control
  get dualEnrollmentCollegeDetaisCValue() {
    return this.previousEducation.get(
      "dualEnrollmentCollegeDetails"
    ) as UntypedFormControl;
  }

  dualEnrollmentCollegeDetailsValue: any = null;
  addDualEnrollmentColleges(): void {
    this.dualEnrollmentCollegeDetailsValue =
      this.previousEducation.controls.dualEnrollmentCollegeDetails.value;
    this.dualEnrollmentCollegeDetails = this.previousEducation.get(
      "dualEnrollmentCollegeDetails"
    ) as UntypedFormArray;
    this.dualEnrollmentCollegeDetails.push(this.initializeDualEnrollment());
    for (let i = 0; i < this.dualEnrollmentCollegeDetailsValue.length; i++) { }
  }
  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    this.previousEducation.get("collegeSearch")?.reset();
    this.previousEducation.get("highSchoolSearch")?.reset();
    this.collegeSearchChar = 0;
    this.collegesSearchChar = 0;
  }
  search(query: string) {
    this.collegeSearchChar = query.length;
    if (this.collegeSearchChar > 1) {
      this.apiService.getHighSchoolList(query).subscribe((data: any) => {
        this.selectedSchool = data;
      });
    }
    let result = this.selectSchool(query);
    this.searchInput = query;
    this.selectedSchool = result;

    if (result.length == 0) {
      this.isSearchData = true;
      this.searchInput = query;
      this.selectedSchool = [];
      // this.selectedSchool.push(query);
    } else {
      this.isSearchData = false;
      this.selectedSchool = result;
    }
  }

  selectSchool(query: string): string[] {
    let result: string[] = [];
    for (let a of this.schoolList) {
      result.push(a);
    }
    return result;
  }
  addSearchData() {
    this.selectedSchool.unshift(this.searchInput);
    this.schoolList.unshift(this.searchInput);
    this.previousEducation.get("highSchoolSearch")?.reset();
    this.selectedSchool = this.schoolList;
    this.isSearchData = false;
  }

  searchCollege(query: string) {
    this.collegesSearchChar = query.length;
    if (this.collegesSearchChar > 1) {
      this.apiService.getCollegeList(query).subscribe((data: any) => {
        this.selectedCollege = data;
      });
    }
    let result = this.selectCollege(query);
    this.searchCollegeInput = query;
    this.selectedCollege = result;

    if (result.length == 0) {
      this.isCollegeSearch = true;
      this.searchCollegeInput = query;
      this.selectedCollege = [];
    } else {
      this.isCollegeSearch = false;
      this.selectedCollege = result;
    }
  }
  selectCollege(query: string): string[] {
    let result: string[] = [];
    for (let a of this.highSchoolList) {
      result.push(a);
    }
    return result;
  }

  addCollegeData() {
    this.selectedCollege.unshift(this.searchCollegeInput);
    this.highSchoolList.unshift(this.searchCollegeInput);
    this.previousEducation.get("collegeSearch")?.reset();
    this.selectedCollege = this.highSchoolList;
    this.isCollegeSearch = false;
  }

  editHighSchoolDiploma() {
    this.isPreviousEducationViewMode = false;
    this.updateFormStateOnEdit();
    this.registerService.updateData(this.previousEducationFormData);
  }

  // update form active state for other forms when editing current form
  updateFormStateOnEdit() {
    let isAcademicFormEditing: boolean = false;
    let isPersonalFormEditing: boolean = false;
    this.previousEduEditData = null;
    isAcademicFormEditing = this._connectionInterestService.getValueChange()[0];
    isPersonalFormEditing = this._connectionInterestService.getValueChange()[2];
    if (isAcademicFormEditing && isPersonalFormEditing) {
      this._connectionInterestService.fldShowValueChange([
        true,
        true,
        true,
        false,
      ]);
    } else if (isPersonalFormEditing) {
      this._connectionInterestService.fldShowValueChange([
        false,
        true,
        true,
        false,
      ]);
    } else if (isAcademicFormEditing) {
      this._connectionInterestService.fldShowValueChange([
        true,
        true,
        false,
        false,
      ]);
    } else {
      this._connectionInterestService.fldShowValueChange([
        false,
        true,
        false,
        false,
      ]);
    }
  } 

  /**
   * Enables the submit button based on the values in the form.
   * @author Ahd. Kabeer hadi on 12-09-2023
   * @return {boolean} True if the submit button should be enabled, false otherwise.
   */

  enableSubmitButton(): boolean {
    let form = this.previousEducation;
  
    // If the form is not available, return true to enable the submit button.
    if (!form) {
      return true;
    }
  
    // Initialize shouldEnable with a default value of false.
    let shouldEnable = false;
  
    // Extract values from the form controls.
    let finalCollegeList = form.get('finalCollegeList')?.value;
    let college = form.get('college')?.value;
    let collegeYear = form.get('collegeGraduationYear')?.value;
    let collegeMonth = form.get('collegeGraduationMonth')?.value;
  
    // Checks which certificate type is selected to adjust the conditions.
    if (this.showSchollDiploma()) {
      let diploma = form.get('highSchoolDiploma')?.value;
  
      // If high school diploma is not selected, return false.
      if (!diploma) {
        return shouldEnable;
      }
  
      let highSchool = form.get('highSchool')?.value;
      let highSchoolYear = form.get('highSchoolGraduationYear')?.value;
      let highSchoolMonth = form.get('highSchoolGraduationMonth')?.value;
  
      let homeSchool = form.get('homeSchool')?.value;
      let homeSchoolYear = form.get('homeSchoolGraduationYear')?.value;
      let homeSchoolMonth = form.get('homeSchoolGraduationMonth')?.value;
  
      let GEDAwardState = form.get('GEDAwardState')?.value;
      let gedYear = form.get('gedGraduationYear')?.value;
      let gedMonth = form.get('gedGraduationMonth')?.value;
      
  
      // Check conditions for enabling the submit button.
      if (['highSchool', 'homeSchool', 'ged'].includes(diploma)) {
        const diplomaValid = (() => {
          if (diploma === 'highSchool') {
            return highSchool && highSchoolYear && highSchoolMonth;
          } else if (diploma === 'homeSchool') {
            return homeSchool && homeSchoolYear && homeSchoolMonth;
          } else if (diploma === 'ged') {
            return GEDAwardState && gedYear && gedMonth;
          }
          return false;
        })();
      
        if (!diplomaValid || (finalCollegeList === 'yes' && (!college || !collegeYear || !collegeMonth))) {
          shouldEnable = false;
        } else {
          shouldEnable = true;
        }
      }
      
    } else if (this.showMasterFields() || this.showDoctorateFields()) {
      // Check conditions for enabling the submit button for master's or doctorate fields.
      if (finalCollegeList == 'yes' && (!college || !collegeYear || !collegeMonth)) {
        shouldEnable = false;
      } else {
        shouldEnable = true;
      }
    } else {
      // Enable the submit button for other cases.
      shouldEnable = true;
    }
  
    return shouldEnable;
  }

  // update form active state for other forms when sumiting current form
  updateFormStateOnSubmit() {
    if (this.enableSubmitButton()) {
      let isAcademicFormEditing: boolean = false;

      isAcademicFormEditing = this._connectionInterestService.getValueChange()[0];
      if (isAcademicFormEditing) {
        this._connectionInterestService.fldShowValueChange([
          true,
          false,
          false,
          false,
        ]);
      } else {
        this._connectionInterestService.fldShowValueChange([
          false,
          false,
          true,
          false,
        ]);
      }
    }
   

  }
}
