import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { AcademicInterestionComponent } from './academic-interestion/academic-interestion.component';
import { AgreementAcceptanceComponent, InstitutionalTermsConditionsDialog, InstitutionalTermsConditionsNotification } from './agreement-acceptance/agreement-acceptance.component';
import { MatSelectSearchModule } from './mat-select-search/mat-select-search.module';
import { connectionInterestService } from './service/connection.service';
import { SharedModule } from './shared/shared.module';
import { PreviousEducationComponent } from './previous-education/previous-education.component';
import { MaterialModule } from './material.module';
import { MatButtonModule } from '@angular/material/button';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { PersonalInformationComponent, personalDetailsNotification } from './personal-information/personal-information.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';


@NgModule({
    declarations: [
        AppComponent,
        PersonalInformationComponent,
        AcademicInterestionComponent,
        AgreementAcceptanceComponent,
        personalDetailsNotification,
        InstitutionalTermsConditionsDialog,
        InstitutionalTermsConditionsNotification,
        PreviousEducationComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        HttpClientModule,
        MatSelectSearchModule,
        MaterialModule,
        BrowserAnimationsModule,
        // NgxIntlTelInputModule,
        ReactiveFormsModule,
        FormsModule,
        // MatSelectCountryModule,
        SharedModule,
        MatButtonModule

    ],
    providers: [connectionInterestService,
        { /* Global injection token for form field hint or error text element.
      By default, MatFormField still reserves exactly one line of space below the field for hint or error text.
      This is the value we want to override. */
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
